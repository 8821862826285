const create_account = {
  question: "Hvordan #opretter jeg min virksomhed på OnLion?",
  answer: [
    {
      text: "Fra forsiden af www.onlion.io, klik $%blue%Tilmelding øverst i højre hjørne."
    },
    {
      text:
        "Du vil nu blive ledt igennem en formular, hvor du indtaster information om dig, og din virksomhed.",
      subSteps: [{ text: "Bemærk at virksomhed og administrator oprettes samtidig." }]
    },
    {
      text:
        "Når du har indtastet al informationen, og accepteret OnLion's betingelser kan du klikke $%blue%Tilmeld_Virksomheden",
      subSteps: [
        {
          text:
            "Du er nu logget ind på din nye konto, og kan komme i gang med at oprette dit vedligehold"
        }
      ]
    }
  ],
  action: "create",
  topic: "account"
};

export default create_account;
