// External
import React, { useState } from "react";
import sim from "string-similarity";
// Internal
import "./search.css";
import SearchUI from "./search.ui"; // Shows an input + list of results based on query;
import { topicInfo, selectTopicQAs } from "../../content/content";

const compare = sim.compareTwoStrings;

const Search = () => {
  const [allQuestions] = useState(formatQuestions(topicInfo));
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);

  return (
    <SearchUI
      value={searchQuery}
      onChange={value => {
        setSearchQuery(value);
        setResults(getQueryResults(value, allQuestions));
      }}
      results={results}
    />
  );
};

/**
 * Get questions most resembling query
 * @param {String} query
 * @param {Array<Object>} allQuestions
 * @returns {Array<Object>}
 */
function getQueryResults(query, allQuestions) {
  const queryWords = query.split(" ");

  return allQuestions
    .map(question => {
      return { ...question, simScore: compare(question.text, query) };
    })
    .sort((a, b) => b.simScore - a.simScore)
    .filter(result => result.simScore > 0.1)
    .slice(0, 3)
    .map(result => {
      const text = result.text.split(" ").map(text => {
        const hasSimilarWord = queryWords.some(
          word => compare(word.toLowerCase(), text.toLowerCase()) > 0.8
        );
        if (hasSimilarWord) {
          return <b key={text}>{` ${text} `}</b>;
        } else {
          return ` ${text} `;
        }
      });

      return {
        ...result,
        text: text
      };
    });
}

/**
 * Get the questions to be queried
 * @param {Object} topics
 * @returns {Array<Object>}
 */
function formatQuestions(topics) {
  return Object.values(topics).reduce((allQuestions, topic) => {
    const topicResources = selectTopicQAs(topic.name);
    const questionsWithLinks = topicResources.map(resource => {
      const { question, action } = resource;

      return {
        text: question
          .split("#")
          .join("")
          .split("_")
          .join(" "), // remove formatting helpers from question string
        link: `/qa/${resource.topic}/${action}`
      };
    });
    return [...allQuestions, ...questionsWithLinks];
  }, []);
}

export default Search;
