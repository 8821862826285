import React from "react";
import "./search.css";
import { Link } from "react-router-dom";

const SearchUI = ({ value, onChange, results }) => {
  return (
    <div className={"Search"}>
      <input
        placeholder={"Eksempel: 'Hvordan opretter jeg udstyr?'"}
        className={"Search__input"}
        autoFocus
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div
        className={`Search__results ${
          results.length === 0 ? "Search__results-hidden" : ""
        }`}
      >
        {mapResults(results, value)}
        {results.length === 0 && value.length > 1 && (
          <span>
            Ingen resultater, skriv mere, eller prøv en anden formulering
          </span>
        )}
      </div>
    </div>
  );
};

function mapResults(results, query) {
  if (results.length > 0) {
    return results.map(({ link, text, simScore }) => (
      <p key={text}>
        <Link
          className={`Search__result ${
            simScore >= 0.4 ? "Search__result-close-match" : ""
          }`}
          to={link}
        >
          <span className="icon">
            <i className="fa fa-info"></i>
          </span>{" "}
          {text.map((word) => word)}
          <span className="icon is-pulled-right">
            <i className="fa fa-arrow-right"></i>
          </span>
        </Link>
      </p>
    ));
  } else if (results.length === 0 && query.length > 0) {
    return <p>Ingen søge resultater, prøv at skrive mere</p>;
  }
}

export default SearchUI;
