const edit_employee = {
  question: "Hvordan #retter jeg en medarbejder, som allerede eksisterer?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Se_medarbejdere i menuen i toppen." },
    {
      text:
        "Du kan nu se en liste over dine medarbejdere; find den medarbejder du vil rette, f.eks. ved at søge."
    },
    { text: "Når du har fundet medarbejderen skal du trykke $Se/Ret i højre side." },
    { text: "Klik nu på knappen $%blue%Ret øverst til højre." },
    {
      text: "Du får nu præsenteret en formular; informationen i skrivefelterne kan rettes.",
      subSteps: [
        { text: "Hvis du ikke vil rette en bestemt værdi kan du lade den forblive som den er." },
        { text: "Hvis du ikke vil rette nogen værdier, kan du klikke 'tilbage' i din browser." }
      ]
    },
    { text: "Når du er færdig kan du klikke $%blue%Gem_ændringer." }
  ],
  action: "edit",
  topic: "employee"
};

export default edit_employee;
