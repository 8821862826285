const create_employee = {
  question: "Hvordan #opretter jeg en medarbejder?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Tilføj_medarbejder i menuen i toppen." },
    {
      text:
        "Du kan nu indtaste information om medarbejderen; hvert skrivefelt har nærmere instruktioner til hvad de hver især skal indeholde."
    },
    {
      text:
        "Når du har udfyldt alle felter kan du klikke opret; Medarbejderen er nu oprettet, og klar til at få tildelt en eller flere vedligeholdsopgaver."
    }
  ],
  action: "create",
  topic: "employee"
};

export default create_employee;
