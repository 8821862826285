const create_admin = {
  question: "Hvordan #opretter jeg en virksomhed (som adminstrator)?",
  answer: [
    {
      text: "Fra forsiden af www.onlion.io, klik $%blue%Tilmelding øverst i højre hjørne."
    },
    {
      text:
        "Du vil nu blive ledt igennem en formular, hvor du indtaster information om dig, og din virksomhed.",
      subSteps: [{ text: "Bemærk at virksomhed og administrator oprettes samtidig." }]
    },
    {
      text:
        "Når du har indtastet al informationen, og accepteret OnLion's betingelser kan du klikke $%blue%Tilmeld_Virksomheden"
    }
  ],
  action: "create",
  topic: "admin"
};

export default create_admin;
