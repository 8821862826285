import create_equipment from "./create";
import edit_equipment from "./edit";
import delete_equipment from "./delete";

import create_note from "./create_note";

const equipmentQAContent = {
  create: create_equipment,
  edit: edit_equipment,
  delete: delete_equipment,
  create_note: create_note
};

export default equipmentQAContent;
