import React from "react";

/*
  This module parses strings in documentation to create richer text.

  Supported tags are:
  #: Underlines the word (used to signify actions),
  $: Creates a tag (used to signify buttons in the OnLion interface),
  %: Color, format: %COLOR% (in 'red', 'blue')
 */

/**
 * Returns an array of JSX elements or strings;
 * @param {string} string
 * @returns {Array<Object | String>} elements
 */
function parse(string) {
  return string.split(" ").map(word => processWord(word));
}

/**
 * Replaces identifier by text feature;
 * @param {string} word
 * @returns {string | Object}
 */
function processWord(word) {
  const firstChar = word[0];
  switch (firstChar) {
    case "#":
      return handleActionWord(word);
    case "$":
      return handleButtonReferenceWord(word);
    default:
      return ` ${word} `;
  }
}

/**
 * Takes an 'Action' word and wraps it to show underline;
 * @param {string} word
 * @returns {Object} React Span element
 */
function handleActionWord(word) {
  const wordWithNoUnderscores = word.replace("_", " ");
  const color = word.split("%")[word.split("%").indexOf("#") + 1];
  const wordWithNoColor = wordWithNoUnderscores.replace(`%${color}%`, "");
  return (
    <span key={word} className={`is-underlined is-underlined-${color}`}>
      {wordWithNoColor.substring(1)}
    </span>
  );
}

/**
 * Takes a 'Reference' word and wraps it to show button-esque styling;
 * @param {string} word
 * @returns {Object} React Span element
 */
function handleButtonReferenceWord(word) {
  const wordWithNoUnderscores = word.split("_").join(" ");
  const color = word.split("%")[word.split("%").indexOf("$") + 1];
  const wordWithNoColor = wordWithNoUnderscores.replace(`%${color}%`, "");
  switch (color) {
    case "red":
      return (
        <span key={word} className="tag is-danger">{` ${wordWithNoColor.substring(1)} `}</span>
      );
    case "blue":
      return <span key={word} className="tag is-link">{` ${wordWithNoColor.substring(1)} `}</span>;
    default:
      return <span key={word} className="tag">{` ${wordWithNoColor.substring(1)} `}</span>;
  }
}

export default parse;
