const transfer_responsibility = {
  question: "Hvordan #overfører_jeg_al_ansvar fra en medarbejder til en anden?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" },
      subSteps: [{ text: "Bemærk at du skal have minimum 2 medarbejdere for at overføre ansvar." }]
    },
    { text: "Klik dernæst på knappen $Se_medarbejdere i menuen i toppen." },
    {
      text:
        "Du kan nu se en liste over dine medarbejdere; find den medarbejder du vil overføre ansvaret FRA."
    },
    { text: "Når du har fundet medarbejderen skal du trykke $Se/Ret i højre side." },
    { text: "Klik nu på knappen $Overdrag_al_ansvar øverst til højre." },
    {
      text:
        "Et vindue åbner nu; her vælges en anden medarbejder, som skal overtage ansvaret for medarbejderens opgaver.",
      subSteps: [
        { text: "Den valgte medarbejder vil overtage ansvaret for ALLE opgaver." },
        {
          text:
            "Ønsker du kun at overføre ansvaret for en enkelt opgave kan dette gøres ved at rette opgaven.",
          elaboration: { text: "Hvordan?", link: "/qa/equipment/edit" }
        }
      ]
    },
    { text: "Når du har valgt en medarbejder kan du klikke $%blue%Overdrag_ansvar." }
  ],
  action: "transfer_responsibility",
  topic: "employee"
};

export default transfer_responsibility;
