import React from "react";
import { Redirect } from "react-router-dom";
import PageWrapper from "../pageWrapper";
import {
  selectTopicGuides,
  selectTopicQAs,
  topicInfo,
} from "../../content/content";
import QAList from "../../common/QAList/QAList";

const AnswersPage = ({
  match: {
    params: { topic },
  },
}) => {
  const QAs = selectTopicQAs(topic);
  const guides = selectTopicGuides(topic);
  const { text, description, icon } = topicInfo.filter(
    (current) => current.name === topic
  )[0];

  if (!QAs) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <PageWrapper>
        <h1 className="is-size-2">
          <span className="icon is-size-4 proper-margin__right">
            <i className={`fa fa-${icon}`} />
          </span>
          <span className={"proper-margin__left"}>{text}</span>
        </h1>
        <br />
        <p className={`is-size-5`}>{description}</p>
        <hr />
        <div className="columns">
          <div className="column is-half">
            <p className={"has-text-weight-bold has-text-grey"}>
              <span className="icon slight-margin__right">
                <i className="fa fa-question-circle" />
              </span>
              <span className={`is-size-5`}>Spørgsmål om dette emne:</span>
            </p>
            <QAList QandAs={QAs} />
          </div>
        </div>
      </PageWrapper>
    );
  }
};

export default AnswersPage;
