const create_equipment = {
  question: "Hvordan #opretter jeg et stykke udstyr?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Tilføj_udstyr i menuen i toppen." },
    {
      text:
        "Du kan nu indtaste information om udstyret; hvert skrivefelt har nærmere instruktioner til hvad de hver især skal indeholde."
    },
    {
      text:
        "Når du har udfyldt alle felter kan du klikke opret; Udstyret er nu oprettet, og klar til at få tilføjet en eller flere vedligeholdsopgaver."
    }
  ],
  action: "create",
  topic: "equipment"
};

export default create_equipment;
