const delete_equipment = {
  question: "Hvordan #sletter jeg et stykke udstyr?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Se_udstyr i menuen i toppen." },
    {
      text:
        "Du kan nu se en liste over dit udstyr; find det stykke udstyr du vil slette, f.eks. ved at søge."
    },
    { text: "Når du har fundet udstyret skal du trykke $Se/Ret i højre side." },
    { text: "Klik på knappen $%red%slet øverst til højre." }
  ],
  action: "delete",
  topic: "equipment"
};

export default delete_equipment;
