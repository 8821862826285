import React from "react";
import { Link } from "react-router-dom";
import parse from "../../utils/parseTextFeatures";
import "./QAList.css";

const QAList = ({ QandAs }) => <div>{mapQAs(QandAs)}</div>;

const mapQAs = QAs => QAs.map(QA => renderSingleQA(QA));

const renderSingleQA = QA => (
  <p className={"QAList_item"} key={QA.action}>
    <Link to={`/qa/${QA.topic}/${QA.action}`}>{parse(QA.question)}</Link>
  </p>
);

export default QAList;
