import create_employee from "./create";
import edit_employee from "./edit";
import delete_employee from "./delete";
import transfer_responsibility from "./transfers_responsibility";

const employeeQAContent = {
  create: create_employee,
  edit: edit_employee,
  delete: delete_employee,
  transfer_responsibility: transfer_responsibility
};

export default employeeQAContent;
