const edit_equipment = {
  question: "Hvordan #retter jeg et stykke udstyr, som allerede eksisterer?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Se_udstyr i menuen i toppen." },
    {
      text:
        "Du kan nu se en liste over dit udstyr; find det stykke udstyr du vil rette, f.eks. ved at søge."
    },
    { text: "Når du har fundet udstyret skal du trykke $Se/Ret i højre side." },
    { text: "Klik nu på knappen $%blue%Ret øverst til højre." },
    {
      text: "Du får nu præsenteret en formular; informationen i skrivefelterne kan rettes.",
      subSteps: [
        { text: "Hvis du ikke vil rette en bestemt værdi kan du lade den forblive som den er." },
        { text: "Hvis du ikke vil rette nogen værdier, kan du klikke 'tilbage' i din browser." }
      ]
    },
    { text: "Når du er færdig kan du klikke $Gem_ændringer." }
  ],
  action: "edit",
  topic: "equipment"
};

export default edit_equipment;
