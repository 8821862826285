const edit_task = {
  question: "Hvordan #retter jeg et stykke udstyr, som allerede eksisterer?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    {
      text: "Find dernæst frem til det stykke udstyr du vil rette.",
      subSteps: [
        { text: "Dette kan gøres ved at gå til det stykke udstyr som opgaven udføres på." },
        {
          text: "Alternativt kan dette gøres ved at gå til den medarbejder som opgaven udføres af."
        }
      ]
    },
    { text: "Når du har fundet opgaven skal du trykke $Se/Ret i højre side." },
    { text: "Klik nu på knappen $%blue%Ret øverst til højre." },
    {
      text: "Du får nu præsenteret en formular; informationen i skrivefelterne kan rettes.",
      subSteps: [
        { text: "Hvis du ikke vil rette en bestemt værdi kan du lade den forblive som den er." },
        { text: "Hvis du ikke vil rette nogen værdier, kan du klikke 'tilbage' i din browser." }
      ]
    },
    { text: "Når du er færdig kan du klikke $Gem_ændringer." }
  ],
  action: "edit",
  topic: "task"
};

export default edit_task;
