import image from "../images/external.jpg";

const external_service = {
  title: "Samarbejde med leverandører af vedligehold",
  image: image,
  sections: [
    {
      title: "",
      paragraphs: [
        {
          text:
            "OnLion kan anvendes i dialogen med, og styring af underleverandører der skal udføre cykliske opgaver, test eller tilsyn for din virksomhed.",
        },
      ],
    },
    {
      title: "Indragelse af leverandør",
      paragraphs: [
        {
          type: "BOLD",
          text: "Mulighed 1.",
        },
        {
          text:
            "Opret en opgave til en intern medarbejder, som huskepunkt til bestilling af service. Når den eksterne leverandør har udført service, kan du eller medarbejderen kvittere for opgavens udførsel.",
        },
        {
          type: "BOLD",
          text: "Mulighed 2.",
        },
        {
          text:
            "Aftal med leverandøren at en repræsentant fra virksomheden oprettes som medarbejder i din virksomheds OnLion-system. Denne repræsentant vil modtage opgaver direkte på sin e-mail, og kan kvittere når service er udført, eventuelt med dokumentation, service rapport, billeder, eller faktura vedhæftet. Repræsentanten vil også have mulighed for at oprette noter, på lige fod med andre medarbejdere i systemet.",
        },
        {
          type: "TIP",
          text:
            "Vi anbefaler hvis muligt at denne mulighed (mulighed 2.) bruges, da direkte kommunikation med systemet fungerer bedst efter vores erfaringer.",
        },
        {
          text:
            "Det kan eventuelt i leverandøraftalen specificeres, at fakturaer kun accepteres via OnLion med aftalt dokumentation vedhæftet. ",
        },
        {
          text:
            "Administratoren modtager advis om opgavens udførsel per e-mail. Herfra kan en eventuel servicerapport vurderes, og faktura kan godkendes og sendes til regnskabsafdelingen.",
        },
      ],
    },
    {
      title: "Lovkrav og ansvar",
      paragraphs: [
        {
          text:
            "OnLion gør det nemt at benytte eksterne leverandører til vigtigt vedligehold - husk dog på, at lovpligtige eftersyn altid er din virksomheds ansvar, og ikke kan af-løftes med en serviceaftale med underleverandører. ",
        },
      ],
    },
  ],
  topics: ["employee", "equipment", "tasks"],
  identifier: "external_service",
};

export default external_service;
