const delete_task = {
  question: "Hvordan #sletter jeg en opgave?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    {
      text: "Find dernæst frem til den opgave du vil rette.",
      subSteps: [
        { text: "Dette kan gøres ved at gå til det stykke udstyr som opgaven udføres på." },
        { text: "Dette kan gøres ved at gå til den medarbejder som opgaven udføres af." }
      ]
    },
    { text: "Når du har fundet opgaven skal du trykke $Se/Ret i højre side." },
    { text: "Klik nu på knappen $%red%slet øverst til højre." },
    {
      text: "Bekræft at du vil slette opgaven."
    }
  ],
  action: "delete",
  topic: "task"
};

export default delete_task;
