import React from "react";
import { Link } from "react-router-dom";
import PageHeading from "../../../common/pageHeader/pageHeading";
import parse from "../../../utils/parseTextFeatures";

const Answer = ({ answer }) => (
  <div className="column is-8">
    <PageHeading className={"has-text-info"}>Svar:</PageHeading>
    {renderAnswer(answer)}
  </div>
);

const renderAnswer = answer => {
  if (typeof answer === "string") {
    return <p className={"QuestionAndAnswer__answer is-size-6"}>{answer}</p>;
  } else if (Array.isArray(answer)) {
    return renderAnswerWithSteps(answer);
  } else {
    console.error("Wrong answer type provided");
  }
};

const renderAnswerWithSteps = answer => answer.map((step, index) => renderStep(step, index));

const renderStep = (step, index) => (
  <div key={index} className={"QuestionAndAnswer__answer"}>
    {renderStepNo(index)}
    {parse(step.text)}
    {step.elaboration && (
      <Link to={step.elaboration.link} className={"is-size-6 slight-margin__left"}>
        {step.elaboration.text}
      </Link>
    )}
    {step.subSteps && renderSubStep(step.subSteps)}
  </div>
);

const renderSubStep = subSteps => {
  return subSteps.map(step => (
    <p key={step.text} className={"QuestionAndAnswer__answer is-size-6"}>
      -{parse(step.text)}
      {step.elaboration && (
        <Link to={step.elaboration.link} className={"is-size-7 slight-margin__left"}>
          {step.elaboration.text}
        </Link>
      )}
    </p>
  ));
};

const renderStepNo = index => (
  <span className={"has-text-grey slight-margin__right is-size-5"}>{index + 1}:</span>
);

export default Answer;
