import equimentQAContent from "./answers/equipment/equipmentQandAs";
import taskQAContent from "./answers/task/taskQandAs";
import employeeQAContent from "./answers/employee/employeeQandAs";
import adminQAContent from "./answers/admin/adminQandAs";
import accountQAContent from "./answers/account/accountQandAs";
import getting_started from "./guides/getting_started";
import external_service from "./guides/external_service";

const content = {
  equipment: equimentQAContent,
  task: taskQAContent,
  employee: employeeQAContent,
  admin: adminQAContent,
  account: accountQAContent
};

/**
 * Get specific QA by topic and action
 * @param {String} topic
 * @param {String} action
 * @returns {Object}
 */
export const selectQA = (topic, action) => {
  try {
    const question = content[topic][action];
    return question ? question : null;
  } catch (err) {
    console.trace(err);
    return null;
  }
};

/**
 * Returns all questions by category
 * @param {string} topic
 * @returns {null} | array<Object>
 */
export const selectTopicQAs = topic => {
  try {
    const relatedQAs = content[topic];
    return relatedQAs ? Object.values(relatedQAs) : null;
  } catch (err) {
    console.trace(err);
    return null;
  }
};

export const topicInfo = [
  {
    name: "task",
    text: "Opgaver",
    icon: "wrench",
    description:
      "Cykliske opgaver er OnLion's livsblod. " +
      "Hver opgave beskriver et vedligehold som " +
      "skal udføres; præcis hvad der skal udføres, " +
      "hvor ofte og af hvem. Når en opgave er " +
      "oprettet sender OnLion systemet automatisk " +
      "reminders til den ansvarlige, holder styr " +
      "på om opgaven er udført, og fører statistik."
  },
  {
    name: "equipment",
    text: "Udstyr",
    icon: "car",
    description:
      "Udstyr oprettet i OnLion er nemt at holde " +
      "styr på. Du kan oprette vedligeholdsopgaver " +
      "på udstyret, og både du og dine medarbejdere kan " +
      "tilføje noter. Med opgaver og noter kan du " +
      "til enhver tid se en komplet og rig historik " +
      "over udstyret."
  },
  {
    name: "employee",
    text: "Medarbejdere",
    icon: "users",
    description:
      "Medarbejdere er givetvis den vigtigste " +
      "komponent til vedligehold. Det er de " +
      "mennesker som står for at udføre vedligeholdet, " +
      "og eventuelt tilføje noter ved fejl, mangler " +
      "eller forslag."
  },
  {
    name: "admin",
    text: "Administrator",
    icon: "user",
    description:
      "Virksomheder " +
      "har som minimum 1 adminstrator, men du kan invitere et ubegrænset " +
      "antal nye adminstratorer. " +
      "Adminstratorer (sandsynligvis dig, hvis du læser dette) " +
      "står for at oprette udstyr, medarbejdere og vedligeholdsopgaver " +
      "i OnLion. Som adminstrator kan du følge med i virksomhedens " +
      "vedligehold, og se statistik for både opgaver og medarbejdere. " +
      "Du kan også modtage feedback fra medarbejdere."
  },
  {
    name: "account",
    text: "Konto",
    icon: "cogs",
    description:
      "Din OnLion konto indeholder din data, samt dine indstillinger, og abonnement. " +
      "Kontoen vil altid have minimum en administrator tilknyttet."
  }
];

export const guides = {
  getting_started: getting_started,
  external_service: external_service
};

export function selectTopicGuides(topic) {
  const guidesArr = Object.values(guides);
  return guidesArr.filter(guide => guide.topics.includes(topic));
}

export default content;
