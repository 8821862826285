import create_admin from "./create";
import invite_admin from "./invite";
import accept_invitation from "./accept_invitation";
import reset_password from "./reset_password";
import log_in from "./log_in";
import log_out from "./log_out";

const adminQAContent = {
  create: create_admin,
  invite: invite_admin,
  accept_invitation: accept_invitation,
  reset_password: reset_password,
  log_in: log_in,
  log_out: log_out
};

export default adminQAContent;
