import React from "react";
import { Link } from "react-router-dom";
import { topicInfo } from "../../../content/content";

const Topics = () => (
  <div className={"topics"}>
    <h4 className="is-size-4">Emner</h4>
    <div className="topics__list">{renderTopics(topicInfo)}</div>
  </div>
);

function renderTopics(topicNames) {
  return topicNames.map((topic) => (
    <Link
      className={`topics__list__item`}
      key={topic.text}
      to={`/topic/${topic.name}`}
    >
      <p>
        <span className="icon">
          <i className={`fa fa-${topic.icon}`}></i>
        </span>{" "}
        {topic.text}
        <span className="icon is-pulled-right arrow-icon">
          <i className={`fa fa-arrow-right`}></i>
        </span>{" "}
      </p>
    </Link>
  ));
}

export default Topics;
