const edit_equipment = {
  question: "Hvordan #opretter jeg en #note på et stykke udstyr?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Se_udstyr i menuen i toppen." },
    {
      text:
        "Du kan nu se en liste over dit udstyr; find det stykke udstyr du vil skrive en note til, f.eks. ved at søge."
    },
    { text: "Når du har fundet udstyret skal du trykke $Se/Ret i højre side." },
    { text: "Klik nu på knappen $%blue%Tilføj_note øverst til højre." },
    {
      text: "Du kan nu indtaste tekst til noten."
    },
    {
      text: "Når du har indtastet teksten kan du klikke $%blue%Gem_note. Noten er nu oprettet."
    }
  ],
  action: "create_note",
  topic: "equipment"
};

export default edit_equipment;
