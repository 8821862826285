import React from "react";
import "./overviewPage.css";
import PageWrapper from "../pageWrapper";
import PageHeading from "../../common/pageHeader/pageHeading";

import Topics from "./components/topics";
import Search from "../../components/search/search.js";
import Guides from "./components/guides";

const OverviewPage = () => (
  <PageWrapper className={"OverviewPage"}>
    <PageHeading>Søg i spørgsmål</PageHeading>
    <Search />
    <hr />
    <Topics />
    <hr />
    <Guides />
  </PageWrapper>
);

export default OverviewPage;
