import React from "react";

const ContactCard = ({ heading, name, email }) => (
  <div>
    <p className="heading">{heading}</p>
    <a
      href={`mailto:${email}?Subject=Angående%20Onlion`}
      className="button is-medium is-info"
    >
      <span>Send mail til {name}</span>
      <span className="icon">
        <i className="fa fa-envelope" />
      </span>
    </a>
  </div>
);

export default ContactCard;
