import React from "react";
import PageWrapper from "../pageWrapper";
import { Link } from "react-router-dom";
import PageHeading from "../../common/pageHeader/pageHeading";
import ContactCard from "./components/contactCard";

const ContactPage = () => (
  <PageWrapper>
    <PageHeading>Kontakt</PageHeading>
    <br />
    <div className="columns is-vcentered">
      <div className="column is-5">
        <p className={"has-text-justified is-size-5"}>
          Har du spørgsmål som du ikke umiddelbart kan finde svar på i{" "}
          <Link to={"/"}>guiden</Link>? eller har du feedback? Du er meget
          velkommen til at kontakte os ved at klikke på en af knapperne til
          højre, for at sende en mail.
        </p>
      </div>
      <div className="column is-2" />
      <div className="column is-5">
        <ContactCard
          heading={"Tekniske spørgsmål og feedback"}
          name={"Nikolaj Gilstrøm"}
          email={"gilstroem@onlion.io"}
        />
        <br />
        <ContactCard
          heading={"Andre forespørgelser"}
          name={"Torben Holmelund"}
          email={"holmelund@onlion.io"}
        />
      </div>
    </div>
  </PageWrapper>
);

export default ContactPage;
