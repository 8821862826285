import React from "react";
import { Link } from "react-router-dom";
import { guides } from "../../../content/content";

const Guides = () => (
  <article className={"Guides"}>
    <h4 className="is-size-4">Guides</h4>
    <div className="guides__item__list">{renderGuides(guides)}</div>
  </article>
);

function renderGuides(guides) {
  return Object.keys(guides).map((guideKey) => (
    <Link
      className={`guides__item`}
      key={guides[guideKey].title}
      to={`/guide/${guideKey}`}
    >
      <img src={guides[guideKey].image} alt="" />
      <p>{guides[guideKey].title}</p>
    </Link>
  ));
}

export default Guides;
