import React from "react";
import PageHeading from "../../../common/pageHeader/pageHeading";
import Answer from "./answer";
import parse from "../../../utils/parseTextFeatures";

const QAndA = ({ question, answer }) => (
  <div className={"QuestionAndAnswer columns"}>
    {renderQuestion(question)}
    <Answer answer={answer} />
  </div>
);

const renderQuestion = question => (
  <div className="column is-4">
    <PageHeading className={"has-text-info"}>Spørgsmål:</PageHeading>
    <p className={"is-size-5"}>{parse(question)}</p>
  </div>
);

export default QAndA;
