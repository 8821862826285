const cancel_subscription = {
  question: "Hvordan #afmelder jeg mit OnLion abonnement?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" },
    },
    {
      text: "Klik nu på $Abonnement i menuen i toppen.",
    },
    {
      text: "Her kan du nu læse mere om dit nuværende abonnement.",
      subSteps: [
        {
          text: "Abonnementer inkluderer Gratis og Ubegrænset.",
        },
      ],
    },
    {
      text:
        "Hvis du har abonnementet 'Ubegrænset' kan du afmelde dit abonnement.",
      subSteps: [
        {
          text: "Dette gøres ved at trykke $afmeld i bunden af dit abonnement.",
        },
        {
          text:
            "Obs: Hvis du vil slette din konto helt skal du kontakte os. Klik på $kontakt i menuen i toppen af skærmen.",
        },
      ],
    },
  ],
  action: "cancel_subscription",
  topic: "account",
};

export default cancel_subscription;
