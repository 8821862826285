import image from "../images/start.jpg";

const getting_started = {
  title: "Kom godt i gang - startguide til OnLion",
  image: image,
  sections: [
    {
      title: "Tilmelding",
      paragraphs: [
        {
          text:
            "Din OnLion oplevelse starter med din tilmelding. Her oprettes virksomheden, og du som adminstrator. Tilmelding er ligefrem, og bør kun tage få minutter.",
        },
        {
          text:
            "Når du tilmelder dig starter du med en gratis konto, som lader dig oprette op til 15 stykker udstyr - det er rigeligt for mange, og der er ingen tidsbegræsning. Du kan senere opgradere dit abonnnement, så du kan oprette mere.",
        },
        {
          type: "TIP",
          text:
            "Det er nemmest hvis du sikrer dig at du indtaster den email som du bruger i virksomheds-sammenhæng, og at firmanavnet er indtastet korrekt (inkluder f.eks. A/S, hvis dit selskab er et aktieselskab).",
        },
      ],
    },
    {
      title: "De første oprettelser",
      paragraphs: [
        {
          text:
            "Efter tilmelding starter det spændende. Det er nu du opretter virksomhedens udstyr, medarbejdere og ikke mindst vedligeholdsopgaver. Da opgaverne ikke kan oprettes uden udstyr og medarbejdere bør disse oprettes først. Husk når du opretter, at de informationer du indtaster kan du senere nemt rette eller slette - du behøver altså ikke at bekymre dig om småfejl og lignende.",
        },
      ],
    },
    {
      title: "Opret dine medarbejdere",
      paragraphs: [
        {
          text:
            "Vi anbefaler at du starter med at oprette de første par medarbejdere. Medarbejdere er en vigtig del af vedligehold, da de står for at udføre det faktiske vedligehold - det er dem som modtager påmindelser om vedligehold, og kvitterer for dets udførelse. Medarbejdere kan også oprette noter på udstyr, for eksempel hvis de bemærker fejl, eller har andre kommentarer - på den måde får du bedre, og mere udførlig dokumentation på dit udstyr.",
        },
        {
          type: "TIP",
          text:
            "Bemærk: OnLion krypterer medarbejderdata, så love og regler om persondata overholdes.",
        },
        {
          text:
            "Medarbejdere tilføjes med navn, email, og funktion. En medarbejders funktion er deres 'rolle', og kan være alt fra operatør, direktør eller mekaniker. Når du opretter en medarbejder vil de automatisk modtage en introduktions email, som forklarer lidt om OnLion fra deres perspektiv.",
        },
        {
          text:
            "Når medarbejdere har udført opgaver kan du blandt andet se statistik over deres rettidighed; altså hvor mange opgaver de udfører, og hvilken andel af disse der er udført inden deadline (mere om opgaver senere i denne guide).",
        },
        {
          type: "TIP",
          text:
            "Det kan være værdifuldt at checke medarbejderes rettidighed med jævne mellemrum, da sent-udførte opgaver kan være et tegn på underliggende problemer; for eksempel kan medarbejderen måske ikke udføre opgaver til tiden på grund af manglende reservedele.",
        },
      ],
    },
    {
      title: "Opret virksomhedens udstyr",
      paragraphs: [
        {
          text:
            "Efter medarbejdere kan udstyr oprettes. Her kan alle de åbenlyse stykker udstyr oprettes først: maskineri, køretøjer, røglemme, gasanlæg, vaskemaskiner, et cetera. Disse oprettes med type og fabrikat, samt udstyrets lokation og identifikationsnummer (id).",
        },
        {
          type: "TIP",
          text:
            "Eksempler: Mercedes Sprinter på parkeringsplads 10, eller Falck Brandslukker i fabrikshallen.",
        },
        {
          text:
            "Det er vigtigt at disse detajler er rigtige, da de senere vil blive brugt af både dig, og medarbejdere til at identificere og finde udstyr. Især id og lokation kan være vigtigt, så udstyret kan findes, og medarbejderen kan sikre sig at de udfører vedligeholdet på det rette udstyr.",
        },
        {
          text:
            "Dernæst kan du tænke ud af boksen, mange vil blive overraskede over hvor meget udstyr de har, som kræver vedligehold. Computere, brandslukkere, rulleporte og facader er bare få eksempler på ting som skal vedligeholdes løbende.",
        },
      ],
    },
    {
      title: "Opret opgaver",
      paragraphs: [
        {
          text:
            "Når både medarbejdere og udstyr er oprettet er det tid til opgaver. Vedligeholdsopgaver i OnLion er opgaver som skal udføres af en medarbejder, på et stykke udstyr, med et fastsat interval. Et eksempel kunne være: Medarbejder A skal udføre olieskift på et udstyr B hvert halve år.",
        },
        {
          text:
            "Opgaver er kerneressourcen i OnLion, og det er med opgaver at OnLion virkelig kan arbejde for dig, og dit vedligehold. Systemet sender automatisk reminders ud til medarbejdere, for alle oprettede opgaver, samt notifikationer om overskredne deadlines.",
        },
        {
          text:
            "Du vil typisk allerede have vedligeholdsopgaver beskrevet, for eksempel i regneark, eller printet ud i checklister. Du vil typisk også kunne finde ud af hvilke vedligehold der skal ske på dit udstyr i dets instruktionsbog. Disse kan overføres til OnLion, så de bliver fulgt op på automatisk, og deres udførsel bliver dokumenteret.",
        },
        {
          text:
            "Opgaver oprettes på bestemte stykker udstyr, med en titel, beskrivelse, frekvens, påmindelsestid og ansvarlig medarbejder. ",
        },
        {
          text:
            "En opgavetitel bør være kort og beskrivende, men ikke indeholde frekvensen, da denne alligevel fremgår på egen hånd - 'årligt check' er for eksempel ikke et særligt beskrivende navn på en opgave, da den ikke fortæller noget om selve opgaven, men kun om frekvensen. ",
        },
        {
          text:
            "Beskrivelsen af opgaven kan være længere, og skal give medarbejderen alle de nødvendige informationer for at kunne udføre vedligeholdet. Det er en god ide at bruge lidt ekstra tid på beskrivelser, og sørge for at de er fyldestgørende, da disse kan medvirke til at vedligeholdet udføres så effektivt og korrekt som muligt. I beskrivelsen kan også uploades en .pdf fil, eventuelt med hele eller dele af instruktionsmanualen. Beskrivelsen kan også indeholde lokation på reservedele, for eksempel ved olieskift kan det være nyttigt at angive hvor den nye olie kan findes, og hvordan den brugte skal håndteres.",
        },
        {
          text:
            "Efter titel og beskrivelse vælges den ansvarlige medarbejder. Dette er personen som skal udføre opgaven. Hvis den samme opgave udføres af flere mennesker bør den hovedansvarlige for opgaven anføres; han har til opgave at kvittere for opgavens udførsel, selvom han måske ikke var den som udførte den. I nogle tilfælde er det en ekstern serviceleverandør som er ansvarlig; her anbefaler vi at udpege en relevant intern medarbejder til at checke, og kvittere for at vedligeholdet er udført, på den eksternes vegne - den interne medarbejder kan også oprette en note ved udført service, som indeholder en kopi af dokumentation for vedligeholdet, hvis en sådan findes. Typisk vil det dog være ligetil hvem der står for en bestemt opgave.",
        },
        {
          text:
            "Påmindelsestid angives i antal dage, og beskriver hvor mange dage før deadline en medarbejder skal påmindes. Hvis en opgave skal udføres hver fredag, og påmindelsestiden er 1 vil medarbejderen altså modtage en påmindelse om Torsdagen, (samt Fredag, og eventuelt dagen efter, hvis deadline er overskredet). Påmindelsestiden bør reflektere hvor lang tid vedligeholdet tager at udføre; drejer det sig om et hurtigt check kan én dag være nok, men er vedligeholdet mere omfattende kan flere dage, eller en uge måske være nødvendig.",
        },
        {
          text:
            "Frekvensen af opgaven angives som et interval, for eksempel dagligt, dagligt (kun hverdage), ugenligt, kvartalvis, årligt, et cetera. Frekvensen vil typisk fremgå af eksisterende opgaver, eller instruktionsmanualer. I nogle tilfælde udføres vedligehold efter X antal timer, kilometer eller andet - i disse tilfælde kan det være nødvendigt at lave et kvalificeret gæt på frekvensen, for eksempel ved at tale med den ansvarlige medarbejder - husk at det i mange tilfælde er bedre at udføre et vedligehold lidt tidligere og oftere, end for sent.",
        },
        {
          text:
            "Den sidste værdi er 'første deadline'. Dette er den første dato hvorpå vedligeholdet skal være udført. Opgaven vil udføres fra denne dato, og derefter med det angivne interval; husk at hvis du angiver en ugenlig opgave, og sætter første deadline til en kommende fredag, vil opgaven blive udført hver fredag fremover (medmindre dette ændres). Husk også at hvis du sætter første deadline til en mandag, men 'påmindelsestiden' er sat til 2 dage, vil medarbejderen modtage en reminder om lørdagen. ",
        },
      ],
    },
    {
      paragraphs: [
        {
          type: "TIP",
          text:
            "Start med medarbejdere og udstyr, og gå derefter til opgaver. Husk at du altid kan rette og slette senere. Husk at tænke ud af boksen, og få alle virksomhedens vedligehold automatiseret.",
        },
      ],
    },
    {
      title: "Daglige drift",
      paragraphs: [
        {
          text:
            "Når alle de basale oprettelser er udført kan du læne dig tilbage, og lade OnLion arbejde for dig. Systemet kører automatisk, og hvis du har lyst kan du let få et overblik over din virksomheds vedligehold ved at logge ind på overblikket. Du vil i slutningen af hver måned modtage en lille rapport per email som viser din virksomheds rettidighed for vedligehold - denne kan bruges til at danne sig et overblik, eller blot konstatere at alting kører OK. ",
        },
        {
          text:
            "Hvis en medarbejder opretter en note på et stykke udstyr vil du modtage en email; i nogle tilfælde er disse blot bemærkninger, og i andre er de vigtig information som du bør tage aktion på - det kan være vigtigt at lytte til medarbejdere, da det typisk er dem som forstår opgaverne bedst.",
        },
        {
          text:
            "Du kan også invitere kollegaer til at adminstrere systemet sammen med dig, og måske til at oprette vedligehold for deres egen afdeling. En guide til dette findes @/qa/admin/invite@her .",
        },
      ],
    },
    {
      title: "Du er nu i gang, og på vej mod nemmere vedligehold",
      paragraphs: [
        {
          text:
            "Vi håber du vil nyde din OnLion oplevelse; systemet er lavet for at gøre vedligehold nemt og overskueligt for dig, og din virksomhed. Hvis du har feedback eller kommentarer til hvordan vi kan gøre enten denne guide, eller systemet endnu bedre, modtager vi mere gerne forslag - se @/contact@kontakt .",
        },
      ],
    },
  ],
  topics: ["employee", "account", "equipment", "tasks"],
  identifier: "getting_started",
};

export default getting_started;
