import create_account from "./create";
import upgrade_account from "./upgrade";
import cancel_subscription from "./cancel_subscription";

const accountQAContent = {
  create: create_account,
  upgrade: upgrade_account,
  cancel_subscription: cancel_subscription,
};

export default accountQAContent;
