import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = () => (
  <nav
    className="header navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div className="container">
      <div className="navbar-brand">
        <Link to={"/"} className="header-logo navbar-item">
          <span className={"logo slight-margin__right"}>OnLion</span>
          <span className={"has-text-black"}>Guide</span>
        </Link>
      </div>

      <div className="navbar-menu">
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link to={"/"} className="button is-small is-outlined">
                <span>Søg</span>
                <span className="icon">
                  <i className="fa fa-search" />
                </span>
              </Link>
              <Link to={"/contact"} className="button is-small is-outlined">
                <span>Kontakt</span>
                <span className="icon">
                  <i className="fa fa-envelope" />
                </span>
              </Link>
              <a
                href={"https://www.onlion.io/"}
                target={"_blank"}
                className="button is-info is-inverted is-small"
              >
                <span>Gå til OnLion</span>
                <span className="icon">
                  <i className="fa fa-arrow-right" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
);

export default Header;
