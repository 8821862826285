const upgrade_account = {
  question: "Hvordan #opgraderer jeg mit OnLion abonnement?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" },
    },
    {
      text: "Klik nu på $Abonnement i menuen i toppen.",
    },
    {
      text:
        "Her kan du nu læse mere om abonnemeter, og opgradere til et ubegrænset abonnement.",
      subSteps: [
        {
          text: "Abonnementer inkluderer Gratis og Ubegrænset.",
        },
      ],
    },
    {
      text:
        "Når du har valgt et nyt abonnement kan du trykke $Opgradér til dette abonnement ved dette.",
    },
    {
      text: "Vælg nu mellem 6 eller 12 måneders abonnementslængde.",
    },
    {
      text:
        "Hvis du ikke tidligere har indtastet information om din virksomhed (Addresse, CVR, et cetera) skal dette også gøres.",
    },
    {
      text:
        "Når du har valgt varighed, og indtastet dine virksomhedsoplysninger kan du klikke $%blue%Gå til betaling",
    },
    {
      text: "Et lille vindue åbner nu, hvori kort-information indtastet.",
      subSteps: [
        {
          text:
            "Betaling kan laves med typiske kreditkort (VISA, VISA Dankort, MasterCard mm.), og er håndteret via betalingsystemet 'Stripe',",
        },
      ],
    },
  ],
  action: "upgrade",
  topic: "account",
};

export default upgrade_account;
