const invite = {
  question: "Hvordan #inviterer jeg en administrator?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    {
      text: "Klik dernæst på knappen $Adminstratorer i menuen i toppen."
    },
    {
      text: "Klik nu på knappen $%blue%Invitér_ny_administrator øverst i højre hjørne."
    },
    {
      text: "Du kan nu indtaste den nye adminstrators navn, og email."
    },
    {
      text: "Når du har udfyldt ebgge felter kan du klikke $%blue%Send_invitation ."
    },
    {
      text: "Den nye adminstrator vil modtage en email, og hvor han kan vælge sit eget password.",
      subSteps: [{ text: "Den nye administrator kan nu oprette medarbejdere, opgaver og udstyr." }]
    }
  ],
  action: "invite",
  topic: "admin"
};

export default invite;
