const log_in = {
  question: "Hvordan #logger_jeg_ind på min OnLion konto?",
  answer: [
    {
      text: "Fra forsiden af www.onlion.io, klik på knappen $Log_ind."
    },
    {
      text:
        "Du bliver nu præsenteret med en formular, hvor du kan indtaste din email og dit password."
    },
    {
      text: "Når du har udfyldt formularen kan du klikke på knappen $%blue%Log_ind.",
      subSteps: [
        { text: "Hvis de udfylde information var korrekte er du er nu logget ind" },
        {
          text: "Hvis du ikke kan huske dit password kan du nulstille det",
          elaboration: { text: "Hvordan?", link: "/qa/admin/reset_password" }
        }
      ]
    },
    { text: "Når du har indtastet din email kan du klikke $%blue%Send_email ." },
    {
      text:
        "Check nu din email-indbakke, her har du modtaget en email om nulstilling af dit password."
    },
    { text: "I emailen, klik på knappen $%blue%Nulstil_password." },
    { text: "Du bliver nu præsenteret med en formular, hvor du kan angive dit nye password." },
    {
      text:
        "Når du har indtastet dit nye password, kan du klikke på knappen $%blue%Opdatér_password.",
      subSteps: [{ text: "Dit password er nu ændret, og du er blevet logget ind på din konto." }]
    }
  ],
  action: "log_in",
  topic: "admin"
};

export default log_in;
