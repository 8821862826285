const reset_password = {
  question: "Hvordan #nulstiller_jeg_mit_password hvis jeg har glemt det?",
  answer: [
    {
      text: "Fra forsiden af www.onlion.io, klik på knappen $Log_ind."
    },
    {
      text: "Klik dernæst på $%blue%Har_du_glemt_dit_password?.",
      subSteps: [{ text: "Denne knap findes under felterne til indtastning af email og password." }]
    },
    { text: "Du kan nu indtaste den email du er tilmeldt OnLion med." },
    { text: "Når du har indtastet din email kan du klikke $%blue%Send_email ." },
    {
      text:
        "Check nu din email-indbakke, her har du modtaget en email om nulstilling af dit password."
    },
    { text: "I emailen, klik på knappen $%blue%Nulstil_password." },
    { text: "Du bliver nu præsenteret med en formular, hvor du kan angive dit nye password." },
    {
      text:
        "Når du har indtastet dit nye password, kan du klikke på knappen $%blue%Opdatér_password.",
      subSteps: [{ text: "Dit password er nu ændret, og du er blevet logget ind på din konto." }]
    }
  ],
  action: "reset_password",
  topic: "admin"
};

export default reset_password;
