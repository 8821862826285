const accept_invitation = {
  question: "Hvordan #accepterer jeg en invitation?",
  answer: [
    {
      text: "Sørg først for, at du er inviteret til at administrere en virksomhed.",
      subSteps: [{ text: "Du vil modtage en email når du er blevet inviteret." }]
    },
    {
      text: "Åben email-invitationen, og klik på $%blue%Tilmeld_dig."
    },
    {
      text: "Du kan nu vælge et password."
    },
    {
      text: "Når du har valgt dit password kan du trykke $%blue%Log_ind .",
      subSteps: [
        {
          text:
            "Du er nu tilmeldt som adminstrator, og kan oprette medarbejdere, udstyr og opgaver på virksomhedens vegne."
        }
      ]
    }
  ],
  action: "accept_invitation",
  topic: "admin"
};

export default accept_invitation;
