const create_task = {
  question: "Hvordan #opretter jeg en opgave?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    {
      text: "Klik dernæst på knappen $Tilføj_opgave i menuen i toppen."
    },
    {
      text: "Vælg dernæst det stykke udstyr, som du vil oprette en opgave på."
    },
    {
      text:
        "Du kan nu indtaste information om opgaven; hvert skrivefelt har nærmere instruktioner til hvad de hver især skal indeholde."
    },
    {
      text:
        "Når du har udfyldt alle felter kan du klikke opret; Opgaven er nu oprettet, og mails vil automatisk blive sendt til den ansvarlige medarbejder."
    }
  ],
  action: "create",
  topic: "task"
};

export default create_task;
