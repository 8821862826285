import React from "react";
import PageWrapper from "../pageWrapper";
import { Redirect, Link } from "react-router-dom";
import { guides } from "../../content/content";
import "./guide.css";

const GuidePage = ({
  match: {
    params: { guide_title }
  }
}) => {
  const relevantGuide = guides[guide_title];
  if (!relevantGuide) {
    return <Redirect to={"/"} />;
  } else {
    return (
      <PageWrapper className={"Guide"}>
        <h1 className={"is-size-2"}>{relevantGuide.title}</h1>
        <Link className={"Guide_back"} to={"/"}>
          <span className="icon">
            <span className="fa fa-arrow-left" />
          </span>{" "}
          Tilbage til forsiden
        </Link>
        {renderSections(relevantGuide.sections)}
        <Link className={"Guide_back"} to={"/"}>
          <span className="icon">
            <span className="fa fa-arrow-left" />
          </span>{" "}
          Tilbage til forsiden
        </Link>
      </PageWrapper>
    );
  }
};

function renderSections(sections) {
  return sections.map(section => {
    return (
      <section className={"Guide__section"} key={section.title + " " + Math.random()}>
        <h2 className={"is-size-4"}>{section.title}</h2>
        <div>{renderParagraphs(section.paragraphs)}</div>
      </section>
    );
  });
}

function renderParagraphs(paragraphs) {
  return paragraphs.map(paragraph => {
    if (paragraph.type !== "TIP" && paragraph.type !== "BOLD") {
      return (
        <p className={"Guide__paragraph"} key={paragraph.text}>
          {parseParagraphText(paragraph.text)}
        </p>
      );
    } else if (paragraph.type === "BOLD") {
      return (
        <p className={"Guide__paragraph"} key={paragraph.text}>
          <b>{parseParagraphText(paragraph.text)}</b>
        </p>
      );
    } else {
      return (
        <blockquote className={"Guide__tip"} key={paragraph.text}>
          {paragraph.text}
        </blockquote>
      );
    }
  });
}

function parseParagraphText(text) {
  return text.split(" ").map(word => {
    if (word[0] !== "@") {
      return ` ${word} `;
    } else {
      const wordSplit = word.split("@");
      const link = wordSplit[1];
      const text = wordSplit[2];

      return (
        <Link key={link + text} to={link}>
          {text}
        </Link>
      );
    }
  });
}

export default GuidePage;
