const log_out = {
  question: "Hvordan #logger_jeg_ud fra min OnLion konto?",
  answer: [
    {
      text:
        "Fra overbliks-siden af OnLion (Startsiden når du er logget ind i systemet), klik på knappen $Log_ud , nederst i venstre hjørne.",
      subSteps: [{ text: "Du er nu logget ud fra din konto." }]
    }
  ],
  action: "log_out",
  topic: "admin"
};

export default log_out;
