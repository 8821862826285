import create_task from "./create";
import edit_task from "./edit";
import delete_task from "./delete";

const taskQAContent = {
  create: create_task,
  edit: edit_task,
  delete: delete_task
};

export default taskQAContent;
