const delete_employee = {
  question: "Hvordan #sletter jeg en medarbejder?",
  answer: [
    {
      text: "Sørg først for, at du er logget ind.",
      elaboration: { text: "Hvordan?", link: "/qa/admin/log_in" }
    },
    { text: "Klik dernæst på knappen $Se_medarbejdere i menuen i toppen." },
    {
      text:
        "Du kan nu se en liste over dine medarbejdere; find den medarbejdere du vil slette, f.eks. ved at søge."
    },
    { text: "Når du har fundet medarbejderen skal du trykke $Se/Ret i højre side." },
    {
      text: "Klik på knappen $%red%slet øverst til højre.",
      subSteps: [
        {
          text:
            "Bemærk: Hvis medarbejderen er ansvarlig for en eller flere opgaver, skal disse først slettes,",
          elaboration: { text: "Hvordan?", link: "/qa/task/delete" }
        },
        {
          text:
            "eller tildeles en anden medarbejder - du kan overføre alle opgaver til en ny medarbejder",
          elaboration: { text: "Hvordan?", link: "/qa/employee/transfer_responsibility" }
        }
      ]
    },
    { text: "Bekræft at du vil slette medarbejderen." }
  ],
  action: "delete",
  topic: "employee"
};

export default delete_employee;
