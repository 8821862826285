import React from "react";
import PageWrapper from "../pageWrapper";
import { Redirect } from "react-router-dom";
import { selectQA, selectTopicQAs } from "../../content/content";
import "./answersPage.css";
import QAndA from "./components/QAndA";
import QAList from "../../common/QAList/QAList";

const AnswersPage = ({
  match: {
    params: { topic, action }
  }
}) => {
  const relevantQA = selectQA(topic, action);
  if (!relevantQA) {
    return <Redirect to={"/"} />;
  } else {
    const relatedQAs = selectTopicQAs(topic);
    const QAsOmitCurrent = relatedQAs.filter(QA => QA.question !== relevantQA.question);
    const { question, answer } = relevantQA;
    return (
      <PageWrapper>
        <QAndA question={question} answer={answer} />

        <div>
          <hr />
          <p className={"has-text-weight-bold has-text-grey"}>Andre spørgsmål om samme emne:</p>
          <QAList QandAs={QAsOmitCurrent} />
        </div>
      </PageWrapper>
    );
  }
};

export default AnswersPage;
