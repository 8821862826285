import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga";
import "bulma/css/bulma.css";

import Header from "./components/header/header";
import ContactPage from "./pages/contact/contactPage";
import AnswersPage from "./pages/answers/answersPage";
import OverviewPage from "./pages/overview/overviewPage";
import TopicPage from "./pages/topic/topicPage";
import GuidePage from "./pages/guide/guidePage";

ReactGA.initialize("UA-98189947-5");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <div>
            <Route path={"/"} component={Header} />
            <Route exact path={"/"} component={OverviewPage} />
            <Route path={"/contact"} component={ContactPage} />
            <Route path={"/qa/:topic/:action"} component={AnswersPage} />
            <Route path={"/topic/:topic"} component={TopicPage} />
            <Route path={"/guide/:guide_title"} component={GuidePage} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
